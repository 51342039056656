import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/labs-react/expandable/Expandable.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/labs-react/expandable" storybook="https://workday.github.io/canvas-kit/?path=/story/labs-expandable--start-icon" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8525&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-labs-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/64134f42ebf472bb42bc7b94dc85fd28/536c7/component-anatomy-expandable-container.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.10810810810811%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of an Expandable Container in its collapsed and expanded states.",
              "title": "Image of an Expandable Container in its collapsed and expanded states.",
              "src": "/static/64134f42ebf472bb42bc7b94dc85fd28/50383/component-anatomy-expandable-container.png",
              "srcSet": ["/static/64134f42ebf472bb42bc7b94dc85fd28/1efb2/component-anatomy-expandable-container.png 370w", "/static/64134f42ebf472bb42bc7b94dc85fd28/50383/component-anatomy-expandable-container.png 740w", "/static/64134f42ebf472bb42bc7b94dc85fd28/536c7/component-anatomy-expandable-container.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Chevron Icon`}</strong>{`: This is part of the header to indicate the opened or closed state of the
container. This icon could be placed on the left or right side of the header. When the chevron is
on the left of the Title, it shows Chevron Right for 'collapsed' and Chevron Down for 'expanded.'
When the chevron is on the right of the Title, it shows Chevron Down for 'collapsed' and Chevron
Up for 'expanded.'`}</li>
        <li parentName="ol"><strong parentName="li">{`Avatar Indicator (Optional)`}</strong>{`: This is used to display a user photo for containers that are
user related. If there is no user photo available, it shows the default user icon.`}</li>
        <li parentName="ol"><strong parentName="li">{`Title`}</strong>{`: The heading text for the information being shown in the content section.`}</li>
        <li parentName="ol"><strong parentName="li">{`Content Section`}</strong>{`: This section is where users can find more information and details about the
container's subject.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`This component highlights the most important details of a section and reveals more when a user
taps or clicks on the header part of the container.`}</li>
        <li parentName="ul">{`Enabling users to hide and show information ensures the design remains focused and relevant to
their expectations.`}</li>
        <li parentName="ul">{`Scanning through the most critical information first makes processing more efficient without
compromising the ability to access additional information.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <p>{`Use an Expandable Container when there is a lot of information to be shown on a page, but some
details can initially be hidden from view.`}</p>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <p>{`Be cautious of hiding critical information or burdening the user with an extra click if they are
likely to read all the content. There is a chance that content hidden within the collapsed state
will not be read or immediately noticed by users.`}</p>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-expandable-containers-impact-the-accessible-experience"
      }}>{`How Expandable Containers Impact the Accessible Experience`}</h3>
      <p>{`Any pattern that can show and hide content in a design must support the non-visual screen reading
experience. Users must be able to perceive whether the content is expanded or collapsed, and users
need to know where they can find the new expanded content.`}</p>
      <p>{`The reading order of the expanded content must logically follow the target button controlling the
content’s visibility for non-visual users to find and understand the content change. A non-visual
screen reading experience is fundamentally linear, like reading a book.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Expandable Container must have a focus indicator that is highly visible against the background
and against the non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for
more information.`}</p>
      <p>{`Expandable Container must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the Expandable Container expandable button`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: Expand or collapse the content`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Expandable Container must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The Expandable Container title is an interactive button`}</li>
        <li parentName="ul">{`The “expanded” or “collapsed” state of the content`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Specify the heading level for the Expandable Container title`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`headingLevel`}</inlineCode>{` prop to assign an appropriate heading level based on the context of the
page content.`}</li>
        <li parentName="ul">{`When using multiple Expandable Containers on a complex screen, use the `}<inlineCode parentName="li">{`as`}</inlineCode>{` prop to render the
`}<inlineCode parentName="li">{`Expandable.Content`}</inlineCode>{` sub-component as a `}<inlineCode parentName="li">{`<section>`}</inlineCode>{` element. Use `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` to reference
the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the `}<inlineCode parentName="li">{`Expandable.Target`}</inlineCode>{` button to create a landmark region for screen readers
with the expanded content.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Set the `}<inlineCode parentName="li">{`aria-expanded`}</inlineCode>{` property onto the `}<inlineCode parentName="li">{`<button>`}</inlineCode>{` element controlling
the content.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Titles should be short and concise, yet long enough to explain what the user would expect to see
when the content is expanded.`}</li>
        <li parentName="ul">{`If titles must be long, make sure it doesn't wrap more than two lines.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=3420-55994&mode=design&t=K9W0rH6sR9YrrIB8-11" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/08a85ed3f800b6978962b66319b37718/536c7/expandable-anatomy-ios.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60.270270270270274%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABhklEQVR42o2TXU/CMBSG/f9/xEsvSYwxdoOJJnKhF3xHDB8DBu0+cAW29vW0m4EIA7u8WdP1fXpOz9lNGMUw4iKE3O7gOC7WYglAQymFqiFCAUZ7jcd4fzk3J0CXgMHSmnR2Acj5P4ByC6fpYf7SQc5TqPYM2OcHii7f62/wVg/s2bOeauB+D9ZsImi9QvMl0kUIleWWo+1TMiny9ecErF4vgGF0ChQkGURwH1wEbx6y8QirOQFTijAjyJ60K0XzYBYUQJWfiZBOEJsNZHcK77aGqB0DUzKOSSMg7aT0Tdq51QSIPkI07u4hezMKJqpImU5jrI7wXViTHukC2DXAFPg6AM0et/YIOVmdT9ksSklVZg6lKmyaakv3ZtJNMmg/gabsNa2Bii+W1DbUETLPrrQNtcKKC1tMpYoKa76BGsyLCit91IeODeJ8hCWQUYQh9di1IYTAE2PXG9s067DfR0wXzQlsjH+VJAmGw4H9CSqBRnGywXTm240Nz7so163D9xfWc8z4AWBxifWx+a+CAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the expandable container",
                "title": "The basic elements of the expandable container",
                "src": "/static/08a85ed3f800b6978962b66319b37718/50383/expandable-anatomy-ios.png",
                "srcSet": ["/static/08a85ed3f800b6978962b66319b37718/1efb2/expandable-anatomy-ios.png 370w", "/static/08a85ed3f800b6978962b66319b37718/50383/expandable-anatomy-ios.png 740w", "/static/08a85ed3f800b6978962b66319b37718/536c7/expandable-anatomy-ios.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong>{`: Shows or hides content`}</li>
          <li parentName="ol"><strong parentName="li">{`Expandable Header`}</strong>{`: Contains a label, chevron, and optional leading element.`}</li>
          <li parentName="ol"><strong parentName="li">{`Lead Element (Optional)`}</strong>{`: Optional leading visual element, such as an Avatar or Icon.`}</li>
          <li parentName="ol"><strong parentName="li">{`Chevron`}</strong>{`: Icon indicating the expanded/collapsed state of the container.`}</li>
          <li parentName="ol"><strong parentName="li">{`Content Slot`}</strong>{`: Expandable content area for more information.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider (Optional)`}</strong>{`: Separates expandable container from other elements.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <p>{`Expandable Containers are used to hide information that may not be needed right away. Tapping on the
header shows or hides content.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/62f1bfa607a61e328883c8522554688b/536c7/expandable-usage.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60.270270270270274%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABVUlEQVR42p1S23KCUAz0/z+ran2qXLTasYDO+FQQOYe73LZJBMf2oWgzkzkBTpbdTSZKx+CMlEZWlDAME7vdJ5q2Q1VVknVdo+s6yZbec3jeHku6m1MP9w44kx+AeQHLsrE0LYSRglIKQRBAa43f4bqu/HwU0DCZ4U5AoigSUK45uc6yTAAPhwMxNEYAe8mu4yBLUxRFQRJbNE0jZ1XVJL95ApAYmiTZttfQSYokSRDH8S2vPg6Svcc8NMm/981WLuZ5hpSYXuVqGcgA+NRQmGWkYoThCb7v33z711CY4Wb7geJyIYY5McxRliX52Aq7YW0elswM3wwLwfk64TA8C0v2k6PrNTuOOw7IH3lyPMH75mGpOQaGD00579fGtmmxw1B84qHs9x7Vjliwsm0cj0esV6u/PeTUcYIv/4TpbIbZfC7n62Ih51C/TKf985zuBtJzj/EN0/SH87p82uEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A vertical stack of Expandable Containers with one expanded.",
                "title": "A vertical stack of Expandable Containers with one expanded.",
                "src": "/static/62f1bfa607a61e328883c8522554688b/50383/expandable-usage.png",
                "srcSet": ["/static/62f1bfa607a61e328883c8522554688b/1efb2/expandable-usage.png 370w", "/static/62f1bfa607a61e328883c8522554688b/50383/expandable-usage.png 740w", "/static/62f1bfa607a61e328883c8522554688b/536c7/expandable-usage.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "touch-targets"
        }}>{`Touch Targets`}</h3>
        <p>{`The entire surface area of the header is interactive. Tapping on the header expands or collapses the
content area, showing or hiding more details. Elements within the header do not have an additional
touch target. Elements contained in expandable content area may have their own touch targets.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep a parallel structure." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "The entire header is tappable, showing or hiding more details",
                    "title": "The entire header is tappable, showing or hiding more details",
                    "src": "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png",
                    "srcSet": ["/static/538c338754178e51f5f9a925b3577e8b/1efb2/expandable-usage-touch-do.png 370w", "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't enables touch targets for elements within the header, such as the Avatar." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/13d892a7fcc5c8975ec37e5554addf28/82158/expandable-usage-touch-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Pressed state on Avatar",
                    "title": "Pressed state on Avatar",
                    "src": "/static/13d892a7fcc5c8975ec37e5554addf28/82158/expandable-usage-touch-dont.png",
                    "srcSet": ["/static/13d892a7fcc5c8975ec37e5554addf28/1efb2/expandable-usage-touch-dont.png 370w", "/static/13d892a7fcc5c8975ec37e5554addf28/82158/expandable-usage-touch-dont.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "progressive-disclosure"
        }}>{`Progressive disclosure`}</h4>
        <p>{`Elements within the header should hint at the content to follow, so that users can make an informed
choice about whether or not they want to see more details.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Labels and supporting visuals should be directly related to the expandable content, the expanded content should not be a surprise." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Expandable icons and text accurately describe content to follow.",
                    "title": "Expandable icons and text accurately describe content to follow.",
                    "src": "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do.png",
                    "srcSet": ["/static/5964c668c8ec52e40503a72cf69ee3b0/1efb2/expandable-usage-content-do.png 370w", "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use supporting visuals purely for decoration - they should tie directly to the content." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Ambiguous icons being used to describe the Expandable",
                    "title": "Ambiguous icons being used to describe the Expandable",
                    "src": "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont.png",
                    "srcSet": ["/static/7bca4d1ddfe3967d2c2e06f8ef252126/1efb2/expandable-usage-content-dont.png 370w", "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "icons"
        }}>{`Icons`}</h4>
        <ul>
          <li parentName="ul">{`On mobile devices, a downward facing chevron is placed at the end of the header to indicate a
collapsed state. When expanding, the icon rotates 180 degrees clockwise until content is fully
displayed.`}</li>
          <li parentName="ul">{`On tablets, a Chevron Right icon is used. Tapping on the header rotates the icon 90 degrees
clockwise until content is fully displayed.`}</li>
          <li parentName="ul">{`The same chevron icon is rotated on expansion for both expanded and collapsed states, rather than
a different chevron icon for expanded and collapsed states.`}</li>
        </ul>
        <h4 {...{
          "id": "content"
        }}>{`Content`}</h4>
        <p>{`Make sure that collapsed content (i.e., hidden by default) isn’t needed by users right away. Hiding
needed actions or information critical to completion of a task can be lead to frustration.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use Expandable Containers to hide non-critical information that may not be needed immediately" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Expandable icons and text accurately describe content to follow.",
                    "title": "Expandable icons and text accurately describe content to follow.",
                    "src": "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png",
                    "srcSet": ["/static/538c338754178e51f5f9a925b3577e8b/1efb2/expandable-usage-touch-do.png 370w", "/static/538c338754178e51f5f9a925b3577e8b/82158/expandable-usage-touch-do.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Hide critical information or actions in the content area" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Primary button being hidden by default",
                    "title": "Primary button being hidden by default",
                    "src": "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png",
                    "srcSet": ["/static/c0724ba4b90e0fb73adbedf90e2ab527/1efb2/expandable-usage-hiding-do.png 370w", "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <p>{`Expandable Containers are helpful to declutter a UI and focus user attention on more important
details. Use an Expandable Container to:`}</p>
        <ul>
          <li parentName="ul">{`Hide content that isn’t needed right away, but may be helpful in a certain context.`}</li>
          <li parentName="ul">{`To keep an interface clutter-free and more manageable. If the amount of content on the screen is
making it difficult to consume, consider hiding some of that content.`}</li>
          <li parentName="ul">{`To keep users in their current view, while remaining in context of a larger idea. Instead of
navigating to another screen, Expandable Containers help keep additional details directly related
to the current screen.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`For tasks that require scanning and comparison, it may be more important to see more content at
once. Having to expand and collapse separate containers to compare information can slow down and
frustrate users.`}</li>
          <li parentName="ul">{`For folder or tree navigation, use a `}<a parentName="li" {...{
              "href": "../content/components/containers/lists#tab=ios"
            }}>{`List`}</a>{` with a
trailing rightward chevron to redirect elsewhere. Expandable Containers are used to show and hide
content on the same screen — not for navigation.`}</li>
          <li parentName="ul">{`If action is required from the user to move along in a flow, consider surfacing that action using
a `}<a parentName="li" {...{
              "href": "../content/components/popups/bottom-sheet#tab=ios"
            }}>{`Sheet`}</a>{` or
`}<a parentName="li" {...{
              "href": "../content/components/popups/alert-dialog#tab=ios"
            }}>{`Alert Dialog`}</a>{` instead. Avoid hiding tasks
necessary for progression within an Expandable Container, as it may get lost.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors"
        }}>{`Behaviors`}</h2>
        <h3 {...{
          "id": "state"
        }}>{`State`}</h3>
        <p>{`Expandable Containers have the default, pressed, and selected states. The default and pressed state
are only applied to the header.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4d44f40abfa8594e6dbc1d63d1143411/536c7/expandable-behavior-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "45.4054054054054%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA0klEQVR42o2S2wqDMBBE/f+vK5Q+ldKHglVbc9mYCCpOs5FQL7FWOGQxM0Oym0xIglRzNJQ20GRCvQ/N1gnOyrhQeoIFjbVwzoGMwTAMSfq+R9d1QTsdIPoTgda1ICJUVYVxHJH6+D8HWut+B0Y0NTCNW7ViCbdl7UsGcu+utztO5wseeYHyLVC86gV5+fJ7z6A9DIxXr6U8GIr+74Q8DEb6wDCgtt1g/dB4j3WbwPhEImS4f42vtV9tMO/B2rmXs7JaKP9+vtSM8nW4tjpk4RUKH3hLsQmJt+X5AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "States of the expandable container",
                "title": "States of the expandable container",
                "src": "/static/4d44f40abfa8594e6dbc1d63d1143411/50383/expandable-behavior-state.png",
                "srcSet": ["/static/4d44f40abfa8594e6dbc1d63d1143411/1efb2/expandable-behavior-state.png 370w", "/static/4d44f40abfa8594e6dbc1d63d1143411/50383/expandable-behavior-state.png 740w", "/static/4d44f40abfa8594e6dbc1d63d1143411/536c7/expandable-behavior-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`States are available on the header when the Expandable Container is expanded.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/536c7/expandable-behavior-state-expanded.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABUklEQVR42o2T63KCMBCF+/7P1+lUrXUUAbkmIYQACqfH4BW1+OObzWXPTnI2+RCywBipNDlHzTX1gqtGMS/YJfgYhFdsbaFLDVMZxhKF1qibBu2+vdC0DddqqOKqKwqNMBoVVFysSosoiCBSgSRM3Hjf7IEeQDdw4LytW5f/b0HJo3celVsKPQzxyIqs7+m8DlLoi+55QWJEBStrVCesYhTWrd9SklvdyysHcYyvxRzz1S9mqyU+Z9/Y7ALEMkeUZ4hFjm0cYZel9Ld0zXld8NhVv0CwoGCZIPyJ4M9DpGsJ5Zfc047cY5FAD/mTHvp03WcPAnp1ot/06Oltvz3DxvgHKFFMe6h5jQtleT8fMemh22CRlF5l9ExIiTTL3Fu0NZtjrcNUbIox0+/QdTljcmocJuU4MagYq8zeccx764Q6Z7Ex2SOaPC348JeVep8nf/kPFhrhSdvhe6wAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "States of the expandable container when expanded",
                "title": "States of the expandable container when expanded",
                "src": "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/50383/expandable-behavior-state-expanded.png",
                "srcSet": ["/static/5a6479c4bc20baed2d6fb0ce2789a4f7/1efb2/expandable-behavior-state-expanded.png 370w", "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/50383/expandable-behavior-state-expanded.png 740w", "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/536c7/expandable-behavior-state-expanded.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Default`}</strong>{`: Interactive`}</li>
          <li parentName="ul"><strong parentName="li">{`Pressed`}</strong>{`: Is being pressed`}</li>
          <li parentName="ul"><strong parentName="li">{`Disabled`}</strong>{`: Non-interactive`}</li>
        </ul>
        <h2 id="ios-examples">Examples</h2>
        <h3 {...{
          "id": "end-icon"
        }}>{`End Icon`}</h3>
        <p>{`By default, 'ExpandableContainerUiComponent' is set to be collapsed. After pressing on the header,
'Expandable. Content'should expand outwards from the container, with the chevron rotating 180
degrees clockwise until expanded.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/30af4ce1c5a35b77d697ad89d8014539/536c7/expandable-example-end.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAtElEQVR42pWR0Q7CIAxF/X33LL7vI1yi/o/6BBTY3AhwLUy3ZRqNJAeSFk7bsJHaQNEKTSP0niNrEWNCAtAPHnJxL7s2ijdNM8QYY2GsA/FJz5jmh5Zjl+sNQuyxrSocmgauu5fiujSyEuaEazu0ri0C7z1SStxRRAgBeRljUNc1dkLgeDrD8t2fQuscpFTo+6GMF0KcYXnCuAYu+JJ9FE5jL0Ze5zJS6YllvAj//ZRv5E95AKwQxv2xvHg3AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an chevron placed at the end",
                "title": "Expandable with an chevron placed at the end",
                "src": "/static/30af4ce1c5a35b77d697ad89d8014539/50383/expandable-example-end.png",
                "srcSet": ["/static/30af4ce1c5a35b77d697ad89d8014539/1efb2/expandable-example-end.png 370w", "/static/30af4ce1c5a35b77d697ad89d8014539/50383/expandable-example-end.png 740w", "/static/30af4ce1c5a35b77d697ad89d8014539/536c7/expandable-example-end.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "start-icon"
        }}>{`Start Icon`}</h3>
        <p>{`On larger screens like tablets, the icon is placed at start of the container before any visuals or
the label. During expansion, the chevron rotates clockwise 90 degrees until the content is fully
expanded`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/eff433e447f4375eec935fa986e404cd/536c7/expandable-example-start.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAnklEQVR42qWRWw6DIBBF3TcbsAuxf3YFdQt1EW35qzwUIRW8ck3TpH+YkpxAMuRk7kz1UgazD1gBxLTC2BFKGyhjDzNoi8qHN65dByEE6vqE++MJN3voLDX5UymaUgon53HrezTNGW17gZQSzjnEGJFSKsaO056sGpSGDwE8LLAzsiwxS1MxXyHb5INi3iwQ/YlxhD0yB/kzXC7kj6Vsj0rI+LwWqF8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Avatar",
                "title": "Expandable with an Avatar",
                "src": "/static/eff433e447f4375eec935fa986e404cd/50383/expandable-example-start.png",
                "srcSet": ["/static/eff433e447f4375eec935fa986e404cd/1efb2/expandable-example-start.png 370w", "/static/eff433e447f4375eec935fa986e404cd/50383/expandable-example-start.png 740w", "/static/eff433e447f4375eec935fa986e404cd/536c7/expandable-example-start.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "avatar"
        }}>{`Avatar`}</h3>
        <p>{`To include an Avatar, place 'ExpandableContainerUIComponent.avatarConfig' between Expandable.Chevron
and Expandable.Label. The Avatar will be placed before the label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8be26c960de1bd4b1a0591fbb1e3b695/536c7/expandable-example-avatar.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlElEQVR42p2RQQrCMBBFe/9LuXElgktPoChiM5Oktc0kfCfRTWoKxcVLCPx55DNdTxaGFxDX90ayqzN6ENe8pgDnB1jnwfpuYfl3ziyF+UfDOOF8OuD5uKvUQ0SQUqqIMSKEAD+MZWZVSMxwGjrud7hdLyUsQVSQKkQi5nmT8FtZw1mcK1Oj2hpF2FpKb+izkD+W8gYLOstkxYDwpgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Avatar",
                "title": "Expandable with an Avatar",
                "src": "/static/8be26c960de1bd4b1a0591fbb1e3b695/50383/expandable-example-avatar.png",
                "srcSet": ["/static/8be26c960de1bd4b1a0591fbb1e3b695/1efb2/expandable-example-avatar.png 370w", "/static/8be26c960de1bd4b1a0591fbb1e3b695/50383/expandable-example-avatar.png 740w", "/static/8be26c960de1bd4b1a0591fbb1e3b695/536c7/expandable-example-avatar.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "icon"
        }}>{`Icon`}</h3>
        <p>{`A leading system icon may be used instead.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a62955135b0005f091d3901aee7e2abc/536c7/expandable-example-icon.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAg0lEQVR42pWR7Q6DIAxFff9nVfoBZREhd0Cim04T9uNAIelJbzo5VpDcwPJBdIjmmqgeLGe8D1D10HZXpP5dUfnto6uwTWPxVWUKMwMR9bqUciLnjJQSgsXeMyD0iDFinhcszmHbcpWUg/Ze1wHhTo/ZIgfrNd/E46fIj0v5XswfS3kDx6/MJeVjWs0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Icon",
                "title": "Expandable with an Icon",
                "src": "/static/a62955135b0005f091d3901aee7e2abc/50383/expandable-example-icon.png",
                "srcSet": ["/static/a62955135b0005f091d3901aee7e2abc/1efb2/expandable-example-icon.png 370w", "/static/a62955135b0005f091d3901aee7e2abc/50383/expandable-example-icon.png 740w", "/static/a62955135b0005f091d3901aee7e2abc/536c7/expandable-example-icon.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "styled"
        }}>{`Styled`}</h3>
        <p>{`Expandable may use tokens to adjust the visual styling of the component.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e3d42e32207020578168e62e83e35712/536c7/expandable-example-styled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "50.810810810810814%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIUlEQVR42p2S206DQBCGef838Qm888p40aRXVUtrtcqpFdgTLJQiy+8sESuINinJF2bIzrd/wjhcZBByiC4qZHnxhe5QWQ6psgHjOetybDE4SIOL+Qxvr1v4YYQgiuAFIeKUIeWiI2G868XEBQOhIphUuLu5xtJd4WH9Anezxb37BH+X4J0p7FOJXSKojzEO80toYUrjavGB22eN+SaBmxqseYtl0uAxPuEmBrHQXYh/hYKEM6+Guy+xCjk8UcOXDQJlMSfoG6ezZxPaH9C2BmVZgnNOdYu/nlyfEdo6J2HTGByqI/USxpjLhX1CK7EJ2SUJpcq7HeuxworS6aLoVuRQVTjW9ST9fvZYl5NyCSZ+QH3KBL0FLar67qdgo1nr+gREHvsVqNK9XwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with depth applied against a grey background.",
                "title": "Expandable with depth applied against a grey background.",
                "src": "/static/e3d42e32207020578168e62e83e35712/50383/expandable-example-styled.png",
                "srcSet": ["/static/e3d42e32207020578168e62e83e35712/1efb2/expandable-example-styled.png 370w", "/static/e3d42e32207020578168e62e83e35712/50383/expandable-example-styled.png 740w", "/static/e3d42e32207020578168e62e83e35712/536c7/expandable-example-styled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "text-wrap"
        }}>{`Text Wrap`}</h3>
        <p>{`Longer titles will wrap to the next line and increase the height of the container.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d401184bdc1fc2cfb388c32c04abf570/536c7/expandable-example-textwrap.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApUlEQVR42o2R6wrDIBSD+/7P2SsFPfbiBbSZcS0rsrL9+IyiiRzSKFmgTYU2UERuaCmq71Q+ZjU6L2I+mGWFcx4+BDgf4C/CW6112HZb4Nu7V9eB/HXPBqUUhmHANE3o+77QdR3GcUTIwcdxIMaY39ri+RkoImjbtgQxmGFUnud5RkoJMf0ReI1s88gc1xU99ye8W7f9eeSvpUhVCgs5S6E+FcNSXnA5yrRHLFA/AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with a label that wraps to another line",
                "title": "Expandable with a label that wraps to another line",
                "src": "/static/d401184bdc1fc2cfb388c32c04abf570/50383/expandable-example-textwrap.png",
                "srcSet": ["/static/d401184bdc1fc2cfb388c32c04abf570/1efb2/expandable-example-textwrap.png 370w", "/static/d401184bdc1fc2cfb388c32c04abf570/50383/expandable-example-textwrap.png 740w", "/static/d401184bdc1fc2cfb388c32c04abf570/536c7/expandable-example-textwrap.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "accordion"
        }}>{`Accordion`}</h3>
        <p>{`By default, Expandable Containers remain expanded until the user chooses to collapse the panel. You
can hoist the models to expand and collapse all three containers at once.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6dcafcf2db646cc128f8996563e9fa66/536c7/expandable-example-accordion.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "49.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABCUlEQVR42o2SCW6EMAxFuf9BEWTfEyDgsT0FTZlKBenLdohffpbBWA/WhUtn7Xy8RGN3Wefhr96BfjgfWJTHlKGUCiFGiKiUM/TeWdtPXLcN2rLw3Hv/F7DUCtZaGMeRNU0TrOsKG0JOUb0gMOPC/wJpEjmTUsI8zyzKCayUAiEE1wTlxZ8AU0oIEtgsQWvNAAIR/IyttefAWhuEEMB7z26dcww4joPV+45b78+3XEoBYww6UwwjVxkvhz7kwb4ffJYPgQUdVgR5dBgYTBfw+ZHLt8PyDaTEh8iiPOXCT8Vah7VnYMLncb/l1haee+8fhMJD1+aSUBQtKONYGh+rNJbHPyXP+KtXwwtn7P5SAHe1wAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A group of Expandable Containers in a accordion. The third one is expanded",
                "title": "A group of Expandable Containers in a accordion. The third one is expanded",
                "src": "/static/6dcafcf2db646cc128f8996563e9fa66/50383/expandable-example-accordion.png",
                "srcSet": ["/static/6dcafcf2db646cc128f8996563e9fa66/1efb2/expandable-example-accordion.png 370w", "/static/6dcafcf2db646cc128f8996563e9fa66/50383/expandable-example-accordion.png 740w", "/static/6dcafcf2db646cc128f8996563e9fa66/536c7/expandable-example-accordion.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><strong parentName="p">{`Class name:`}</strong>{` `}<inlineCode parentName="p">{`ExpandableContainer`}</inlineCode>{` `}<strong parentName="p">{`Module name:`}</strong>{` `}<inlineCode parentName="p">{`UIComponentsPlugin`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-Swift"
          }}>{`public init(
  featureData: FeatureMetricsData,
  eventStream: PassthroughSubject<ComponentEvent, Never> = PassthroughSubject<ComponentEvent, Never>(),
  title: String,
  isExpanded: State<Bool> = .init(initialValue: false),
  leadingItem: LeadingListItemContent? = nil,
  semanticContext: State<SemanticContext> = .init(initialValue: [.inactive]),
  removePaddingOnHeader: Bool = false,
  showDivider: Bool = true,
  localizer: LocalizationAdapting,
  @ViewBuilder content: @escaping () -> Content
)
`}</code></pre>
        <h3 {...{
          "id": "props"
        }}>{`Props`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`featureData`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`FeatureMetricsData`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`title`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`The written title to display for the header of the container`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`isExpanded`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Bool`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`False`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional `}<inlineCode parentName="td">{`Bool`}</inlineCode>{` for the expansion state. This allows you to set whether you want the container expanded on startup or not. Expansion state is then managed by the container itself.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`leadingItem`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`LeadingListItemContent`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional `}<inlineCode parentName="td">{`LeadingListItemContent`}</inlineCode>{` which allows you to display an image or a standard avatar in the header.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`semanaticContext`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticContext`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`[.inactive]`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Managed `}<inlineCode parentName="td">{`SemanticContext`}</inlineCode>{` of the state for the entire container. Default is to let expandable container handle it itself. This allows for enabling, disabling, warning, error, and required states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`removePaddingOnHeader`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Bool`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional bool which will override the padding in the header. Default is false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`showDivider`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Bool`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`true`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional bool that can show the divider underneath the container. Default is true`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`localizer`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`LocalizationAdapting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Localization provider`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`content`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`@ViewBuilder content: @escaping () -> Content`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`@ViewBuilder closure for the content you want displayed inside the container.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/wUewAjqFBnLx2ka2B49FUR/%F0%9F%9A%A7-BETA%3A-Mobile-UI-Components-%F0%9F%9A%A7?type=design&node-id=3420-55994&mode=design&t=K9W0rH6sR9YrrIB8-11" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0b0c43dffa85967eee934eceb8af6bf6/536c7/expandable-anatomy-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60.270270270270274%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABp0lEQVR42o2Ti07CMBSGff8X8QU0JsYEdgE2iMaYIN6hXAYyXTvsLrC1v6dMNKKgTU66dunX85//9EDEC5iIuECWL2HbDl75HICGUgrbY7Pn+z7YcASZpNgwTBz8ADoEDOfrQ7rYA2x5/wBmOWyviZl/gzJKoXoBsCq/aJqAZhY5WodHYNMAUiZ7gKsVLM9DeNGGjuZInwVUURoOhV7P1QrwTmtg4/FuIKfIwhjOmYPwvIliNMDLjIApZVgQYUWxJHCuDRWeS5InE8hlDiHiLSBtcCmR3U7QPDxB3FsAEwKMSGJfIeklWN4TbUDrJxI9oxoekymXPcjrEcRil2RVwrJciCsOjCmbAcnsayQ3BpgDrLoA0w/g3SMke94BpCyzjFy2bJLK17IUydNGriygp2/QtFdmHy43/KqGv0re6sOXiFemqsphHUmoh1nVNmX51Yds+IfLBLQIKKJo0yU/RrkN/Kuxa/U6WL+PIWMIjItkVrfbRRAESNMUnU4HYRhSrev7G5tTHd4o/QHdatFrcVwXbqOBZqu1fj2f33b1z2+3wXn8DWaA7+Wgh/983nqkAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the expandable",
                "title": "The basic elements of the expandable",
                "src": "/static/0b0c43dffa85967eee934eceb8af6bf6/50383/expandable-anatomy-android.png",
                "srcSet": ["/static/0b0c43dffa85967eee934eceb8af6bf6/1efb2/expandable-anatomy-android.png 370w", "/static/0b0c43dffa85967eee934eceb8af6bf6/50383/expandable-anatomy-android.png 740w", "/static/0b0c43dffa85967eee934eceb8af6bf6/536c7/expandable-anatomy-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Container`}</strong></li>
          <li parentName="ol"><strong parentName="li">{`Expandable Header`}</strong>{`: Contains a label, chevron, and optional leading element.`}</li>
          <li parentName="ol"><strong parentName="li">{`Lead Content (Optional)`}</strong>{`: Optional leading visual element, such as an Avatar or Icon.`}</li>
          <li parentName="ol"><strong parentName="li">{`Chevron`}</strong>{`: Icon indicating the opened or closed state of the container by pointing in the
direction of expansion.`}</li>
          <li parentName="ol"><strong parentName="li">{`Content Slot`}</strong>{`: Expandable area where users can find more information on the container's
subject.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider`}</strong>{` (Optional): Separates expandable container from other elements.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <p>{`Expandable Containers are used to hide information that may not be needed right away. Tapping on the
header reveals content as requested.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/62f1bfa607a61e328883c8522554688b/536c7/expandable-usage-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60.270270270270274%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABVUlEQVR42p1S23KCUAz0/z+ran2qXLTasYDO+FQQOYe73LZJBMf2oWgzkzkBTpbdTSZKx+CMlEZWlDAME7vdJ5q2Q1VVknVdo+s6yZbec3jeHku6m1MP9w44kx+AeQHLsrE0LYSRglIKQRBAa43f4bqu/HwU0DCZ4U5AoigSUK45uc6yTAAPhwMxNEYAe8mu4yBLUxRFQRJbNE0jZ1XVJL95ApAYmiTZttfQSYokSRDH8S2vPg6Svcc8NMm/981WLuZ5hpSYXuVqGcgA+NRQmGWkYoThCb7v33z711CY4Wb7geJyIYY5McxRliX52Aq7YW0elswM3wwLwfk64TA8C0v2k6PrNTuOOw7IH3lyPMH75mGpOQaGD00579fGtmmxw1B84qHs9x7Vjliwsm0cj0esV6u/PeTUcYIv/4TpbIbZfC7n62Ih51C/TKf985zuBtJzj/EN0/SH87p82uEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A vertical stack of Expandable Containers with one expanded.",
                "title": "A vertical stack of Expandable Containers with one expanded.",
                "src": "/static/62f1bfa607a61e328883c8522554688b/50383/expandable-usage-android.png",
                "srcSet": ["/static/62f1bfa607a61e328883c8522554688b/1efb2/expandable-usage-android.png 370w", "/static/62f1bfa607a61e328883c8522554688b/50383/expandable-usage-android.png 740w", "/static/62f1bfa607a61e328883c8522554688b/536c7/expandable-usage-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "touch-targets-1"
        }}>{`Touch Targets`}</h3>
        <p>{`The entire surface area of the header is interactive. Tapping on the header will expand or collapse
the content area, revealing or hiding more details. Any elements within the header should not have
an additional touch target; however, elements within the expandable content may be tappable.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep a parallel structure." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/5105edd95976eda7ac4311f958565b7b/82158/expandable-usage-touch-do-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "The entire header is tappable, showing or hiding more details",
                    "title": "The entire header is tappable, showing or hiding more details",
                    "src": "/static/5105edd95976eda7ac4311f958565b7b/82158/expandable-usage-touch-do-android.png",
                    "srcSet": ["/static/5105edd95976eda7ac4311f958565b7b/1efb2/expandable-usage-touch-do-android.png 370w", "/static/5105edd95976eda7ac4311f958565b7b/82158/expandable-usage-touch-do-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't enables touch targets for elements within the header, such as the Avatar." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/3745edd1f30d1cffa653beab1aff5eca/82158/expandable-usage-touch-dont-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Pressed state on Avatar",
                    "title": "Pressed state on Avatar",
                    "src": "/static/3745edd1f30d1cffa653beab1aff5eca/82158/expandable-usage-touch-dont-android.png",
                    "srcSet": ["/static/3745edd1f30d1cffa653beab1aff5eca/1efb2/expandable-usage-touch-dont-android.png 370w", "/static/3745edd1f30d1cffa653beab1aff5eca/82158/expandable-usage-touch-dont-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "aid-in-decision-making"
        }}>{`Aid in Decision Making`}</h4>
        <p>{`Elements within the header should hint at the content to follow, so that users can make an informed
choice about whether or not they want to see more details.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Labels and supporting visuals should be directly related to the expandable content, the expanded content should not be a surprise." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Expandable icons and text accurately describe content to follow.",
                    "title": "Expandable icons and text accurately describe content to follow.",
                    "src": "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do-android.png",
                    "srcSet": ["/static/5964c668c8ec52e40503a72cf69ee3b0/1efb2/expandable-usage-content-do-android.png 370w", "/static/5964c668c8ec52e40503a72cf69ee3b0/82158/expandable-usage-content-do-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Don't use supporting visuals purely for decoration - they should tie directly to the content." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont-android.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Ambiguous icons being used to describe the Expandable",
                    "title": "Ambiguous icons being used to describe the Expandable",
                    "src": "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont-android.png",
                    "srcSet": ["/static/7bca4d1ddfe3967d2c2e06f8ef252126/1efb2/expandable-usage-content-dont-android.png 370w", "/static/7bca4d1ddfe3967d2c2e06f8ef252126/82158/expandable-usage-content-dont-android.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "disclosure-icons"
        }}>{`Disclosure Icons`}</h4>
        <ul>
          <li parentName="ul">{`On mobile devices, a downward facing chevron is placed at the end of the header to indicate a
collapsed state. When expanding, the icon rotates 180 degrees clockwise until content is fully
displayed.`}</li>
          <li parentName="ul">{`For tablets, a leading rightward icon is used,rotating 90 degrees clockwise instead.`}</li>
          <li parentName="ul">{`The same chevron icon is rotated on expansion for both expanded and collapsed states, rather than
a different chevron icon for expanded and collapsed states.`}</li>
        </ul>
        <h4 {...{
          "id": "hiding-content"
        }}>{`Hiding Content`}</h4>
        <p>{`Make sure that collapsed content (i.e., hidden by default) isn’t needed by users right away. Hiding
needed actions or information critical to completion of a task can be lead to frustration.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Use Expandable Containers to hide non-critical information that may not be needed immediately" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Expandable icons and text accurately describe content to follow.",
                    "title": "Expandable icons and text accurately describe content to follow.",
                    "src": "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png",
                    "srcSet": ["/static/c0724ba4b90e0fb73adbedf90e2ab527/1efb2/expandable-usage-hiding-do.png 370w", "/static/c0724ba4b90e0fb73adbedf90e2ab527/82158/expandable-usage-hiding-do.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Hide critical information or actions in the content area" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/872093465baab615e466a98d663a35cd/82158/expandable-usage-hiding-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "100%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Primary button being hidden by default",
                    "title": "Primary button being hidden by default",
                    "src": "/static/872093465baab615e466a98d663a35cd/82158/expandable-usage-hiding-dont.png",
                    "srcSet": ["/static/872093465baab615e466a98d663a35cd/1efb2/expandable-usage-hiding-dont.png 370w", "/static/872093465baab615e466a98d663a35cd/82158/expandable-usage-hiding-dont.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <p>{`Expandable Containers are helpful to declutter a UI and focus user attention on more important
details. Use an Expandable Container to:`}</p>
        <ul>
          <li parentName="ul">{`Hide content that isn’t needed right away, but may be helpful in a certain context.`}</li>
          <li parentName="ul">{`To keep an interface clutter-free and more manageable. If the amount of content on the screen is
making it difficult to consume, consider hiding some of that content.`}</li>
          <li parentName="ul">{`To keep users in their current view, while remaining in context of a larger idea. Instead of
navigating to another screen, Expandable Containers help keep additional details directly related
to the current screen.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`For tasks that require scanning and comparison, it may be more important to see more content at
once. Having to expand and collapse separate containers to compare information can slow down and
frustrate users.`}</li>
          <li parentName="ul">{`For folder or tree navigation, use a `}<a parentName="li" {...{
              "href": "../content/components/containers/lists#tab=android"
            }}>{`List`}</a>{`
with a trailing rightward chevron to redirect elsewhere. Expandable Containers are used to show
and hide content on the same screen — not for navigation.`}</li>
          <li parentName="ul">{`If action is required from the user to move along in a flow, consider surfacing that action using
a `}<a parentName="li" {...{
              "href": "../content/components/popups/bottom-sheet#tab=android"
            }}>{`Sheet`}</a>{` or
`}<a parentName="li" {...{
              "href": "../content/components/popups/alert-dialog#tab=android"
            }}>{`Alert Dialog`}</a>{` instead. Avoid hiding tasks
necessary for progression within an Expandable Container, as it may get lost.`}</li>
        </ul>
        <h2 {...{
          "id": "behaviors-1"
        }}>{`Behaviors`}</h2>
        <h3 {...{
          "id": "state-1"
        }}>{`State`}</h3>
        <p>{`Expandable Containers have the default, pressed, and selected states. The default and pressed state
are only applied to the header.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4d44f40abfa8594e6dbc1d63d1143411/536c7/expandable-behavior-state.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "45.4054054054054%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA0klEQVR42o2S2wqDMBBE/f+vK5Q+ldKHglVbc9mYCCpOs5FQL7FWOGQxM0Oym0xIglRzNJQ20GRCvQ/N1gnOyrhQeoIFjbVwzoGMwTAMSfq+R9d1QTsdIPoTgda1ICJUVYVxHJH6+D8HWut+B0Y0NTCNW7ViCbdl7UsGcu+utztO5wseeYHyLVC86gV5+fJ7z6A9DIxXr6U8GIr+74Q8DEb6wDCgtt1g/dB4j3WbwPhEImS4f42vtV9tMO/B2rmXs7JaKP9+vtSM8nW4tjpk4RUKH3hLsQmJt+X5AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "States of the expandable container",
                "title": "States of the expandable container",
                "src": "/static/4d44f40abfa8594e6dbc1d63d1143411/50383/expandable-behavior-state.png",
                "srcSet": ["/static/4d44f40abfa8594e6dbc1d63d1143411/1efb2/expandable-behavior-state.png 370w", "/static/4d44f40abfa8594e6dbc1d63d1143411/50383/expandable-behavior-state.png 740w", "/static/4d44f40abfa8594e6dbc1d63d1143411/536c7/expandable-behavior-state.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`States are available on the header when the Expandable Container is expanded.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/536c7/expandable-behavior-state-expanded.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "63.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABUklEQVR42o2T63KCMBCF+/7P1+lUrXUUAbkmIYQACqfH4BW1+OObzWXPTnI2+RCywBipNDlHzTX1gqtGMS/YJfgYhFdsbaFLDVMZxhKF1qibBu2+vdC0DddqqOKqKwqNMBoVVFysSosoiCBSgSRM3Hjf7IEeQDdw4LytW5f/b0HJo3celVsKPQzxyIqs7+m8DlLoi+55QWJEBStrVCesYhTWrd9SklvdyysHcYyvxRzz1S9mqyU+Z9/Y7ALEMkeUZ4hFjm0cYZel9Ld0zXld8NhVv0CwoGCZIPyJ4M9DpGsJ5Zfc047cY5FAD/mTHvp03WcPAnp1ot/06Oltvz3DxvgHKFFMe6h5jQtleT8fMemh22CRlF5l9ExIiTTL3Fu0NZtjrcNUbIox0+/QdTljcmocJuU4MagYq8zeccx764Q6Z7Ex2SOaPC348JeVep8nf/kPFhrhSdvhe6wAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "States of the expandable container when expanded",
                "title": "States of the expandable container when expanded",
                "src": "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/50383/expandable-behavior-state-expanded.png",
                "srcSet": ["/static/5a6479c4bc20baed2d6fb0ce2789a4f7/1efb2/expandable-behavior-state-expanded.png 370w", "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/50383/expandable-behavior-state-expanded.png 740w", "/static/5a6479c4bc20baed2d6fb0ce2789a4f7/536c7/expandable-behavior-state-expanded.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Default`}</strong>{`: Interactive`}</li>
          <li parentName="ul"><strong parentName="li">{`Pressed`}</strong>{`: Is being pressed`}</li>
          <li parentName="ul"><strong parentName="li">{`Disabled`}</strong>{`: Non-interactive`}</li>
        </ul>
        <h2 id="android-examples">Examples</h2>
        <h3 {...{
          "id": "end-icon-1"
        }}>{`End Icon`}</h3>
        <p>{`By default, 'ExpandableContainerUiComponent' is set to be collapsed. After pressing on the header,
'Expandable. Content'should expand outwards from the container, with the chevron rotating 180
degrees clockwise until expanded.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/30af4ce1c5a35b77d697ad89d8014539/536c7/expandable-example-end.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAtElEQVR42pWR0Q7CIAxF/X33LL7vI1yi/o/6BBTY3AhwLUy3ZRqNJAeSFk7bsJHaQNEKTSP0niNrEWNCAtAPHnJxL7s2ijdNM8QYY2GsA/FJz5jmh5Zjl+sNQuyxrSocmgauu5fiujSyEuaEazu0ri0C7z1SStxRRAgBeRljUNc1dkLgeDrD8t2fQuscpFTo+6GMF0KcYXnCuAYu+JJ9FE5jL0Ze5zJS6YllvAj//ZRv5E95AKwQxv2xvHg3AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an chevron placed at the end",
                "title": "Expandable with an chevron placed at the end",
                "src": "/static/30af4ce1c5a35b77d697ad89d8014539/50383/expandable-example-end.png",
                "srcSet": ["/static/30af4ce1c5a35b77d697ad89d8014539/1efb2/expandable-example-end.png 370w", "/static/30af4ce1c5a35b77d697ad89d8014539/50383/expandable-example-end.png 740w", "/static/30af4ce1c5a35b77d697ad89d8014539/536c7/expandable-example-end.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "start-icon-1"
        }}>{`Start Icon`}</h3>
        <p>{`On larger screens like tablets, the icon is placed at start of the container before any visuals or
the label. During expansion, the chevron rotates clockwise 90 degrees until the content is fully
expanded`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/eff433e447f4375eec935fa986e404cd/536c7/expandable-example-start.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAnklEQVR42qWRWw6DIBBF3TcbsAuxf3YFdQt1EW35qzwUIRW8ck3TpH+YkpxAMuRk7kz1UgazD1gBxLTC2BFKGyhjDzNoi8qHN65dByEE6vqE++MJN3voLDX5UymaUgon53HrezTNGW17gZQSzjnEGJFSKsaO056sGpSGDwE8LLAzsiwxS1MxXyHb5INi3iwQ/YlxhD0yB/kzXC7kj6Vsj0rI+LwWqF8AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Avatar",
                "title": "Expandable with an Avatar",
                "src": "/static/eff433e447f4375eec935fa986e404cd/50383/expandable-example-start.png",
                "srcSet": ["/static/eff433e447f4375eec935fa986e404cd/1efb2/expandable-example-start.png 370w", "/static/eff433e447f4375eec935fa986e404cd/50383/expandable-example-start.png 740w", "/static/eff433e447f4375eec935fa986e404cd/536c7/expandable-example-start.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "avatar-1"
        }}>{`Avatar`}</h3>
        <p>{`To include an Avatar, place 'ExpandableContainerUIComponent.avatarConfig' between Expandable.Chevron
and Expandable.Label. The Avatar will be placed before the label.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8be26c960de1bd4b1a0591fbb1e3b695/536c7/expandable-example-avatar.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlElEQVR42p2RQQrCMBBFe/9LuXElgktPoChiM5Oktc0kfCfRTWoKxcVLCPx55DNdTxaGFxDX90ayqzN6ENe8pgDnB1jnwfpuYfl3ziyF+UfDOOF8OuD5uKvUQ0SQUqqIMSKEAD+MZWZVSMxwGjrud7hdLyUsQVSQKkQi5nmT8FtZw1mcK1Oj2hpF2FpKb+izkD+W8gYLOstkxYDwpgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Avatar",
                "title": "Expandable with an Avatar",
                "src": "/static/8be26c960de1bd4b1a0591fbb1e3b695/50383/expandable-example-avatar.png",
                "srcSet": ["/static/8be26c960de1bd4b1a0591fbb1e3b695/1efb2/expandable-example-avatar.png 370w", "/static/8be26c960de1bd4b1a0591fbb1e3b695/50383/expandable-example-avatar.png 740w", "/static/8be26c960de1bd4b1a0591fbb1e3b695/536c7/expandable-example-avatar.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "icon-1"
        }}>{`Icon`}</h3>
        <p>{`A leading system icon may be used instead.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a62955135b0005f091d3901aee7e2abc/536c7/expandable-example-icon.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAg0lEQVR42pWR7Q6DIAxFff9nVfoBZREhd0Cim04T9uNAIelJbzo5VpDcwPJBdIjmmqgeLGe8D1D10HZXpP5dUfnto6uwTWPxVWUKMwMR9bqUciLnjJQSgsXeMyD0iDFinhcszmHbcpWUg/Ze1wHhTo/ZIgfrNd/E46fIj0v5XswfS3kDx6/MJeVjWs0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with an Icon",
                "title": "Expandable with an Icon",
                "src": "/static/a62955135b0005f091d3901aee7e2abc/50383/expandable-example-icon.png",
                "srcSet": ["/static/a62955135b0005f091d3901aee7e2abc/1efb2/expandable-example-icon.png 370w", "/static/a62955135b0005f091d3901aee7e2abc/50383/expandable-example-icon.png 740w", "/static/a62955135b0005f091d3901aee7e2abc/536c7/expandable-example-icon.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "styled-1"
        }}>{`Styled`}</h3>
        <p>{`Expandable may use tokens to adjust the visual styling of the component.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e3d42e32207020578168e62e83e35712/536c7/expandable-example-styled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "50.810810810810814%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABIUlEQVR42p2S206DQBCGef838Qm888p40aRXVUtrtcqpFdgTLJQiy+8sESuINinJF2bIzrd/wjhcZBByiC4qZHnxhe5QWQ6psgHjOetybDE4SIOL+Qxvr1v4YYQgiuAFIeKUIeWiI2G868XEBQOhIphUuLu5xtJd4WH9Anezxb37BH+X4J0p7FOJXSKojzEO80toYUrjavGB22eN+SaBmxqseYtl0uAxPuEmBrHQXYh/hYKEM6+Guy+xCjk8UcOXDQJlMSfoG6ezZxPaH9C2BmVZgnNOdYu/nlyfEdo6J2HTGByqI/USxpjLhX1CK7EJ2SUJpcq7HeuxworS6aLoVuRQVTjW9ST9fvZYl5NyCSZ+QH3KBL0FLar67qdgo1nr+gREHvsVqNK9XwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with depth applied against a grey background.",
                "title": "Expandable with depth applied against a grey background.",
                "src": "/static/e3d42e32207020578168e62e83e35712/50383/expandable-example-styled.png",
                "srcSet": ["/static/e3d42e32207020578168e62e83e35712/1efb2/expandable-example-styled.png 370w", "/static/e3d42e32207020578168e62e83e35712/50383/expandable-example-styled.png 740w", "/static/e3d42e32207020578168e62e83e35712/536c7/expandable-example-styled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "text-wrap-1"
        }}>{`Text Wrap`}</h3>
        <p>{`Longer titles will wrap to the next line and increase the height of the container.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d401184bdc1fc2cfb388c32c04abf570/536c7/expandable-example-textwrap.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApUlEQVR42o2R6wrDIBSD+/7P2SsFPfbiBbSZcS0rsrL9+IyiiRzSKFmgTYU2UERuaCmq71Q+ZjU6L2I+mGWFcx4+BDgf4C/CW6112HZb4Nu7V9eB/HXPBqUUhmHANE3o+77QdR3GcUTIwcdxIMaY39ri+RkoImjbtgQxmGFUnud5RkoJMf0ReI1s88gc1xU99ye8W7f9eeSvpUhVCgs5S6E+FcNSXnA5yrRHLFA/AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Expandable with a label that wraps to another line",
                "title": "Expandable with a label that wraps to another line",
                "src": "/static/d401184bdc1fc2cfb388c32c04abf570/50383/expandable-example-textwrap.png",
                "srcSet": ["/static/d401184bdc1fc2cfb388c32c04abf570/1efb2/expandable-example-textwrap.png 370w", "/static/d401184bdc1fc2cfb388c32c04abf570/50383/expandable-example-textwrap.png 740w", "/static/d401184bdc1fc2cfb388c32c04abf570/536c7/expandable-example-textwrap.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "accordion-1"
        }}>{`Accordion`}</h3>
        <p>{`By default, Expandable Containers remain expanded until the user chooses to collapse the panel. You
can hoist the models to expand and collapse all three containers at once.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6dcafcf2db646cc128f8996563e9fa66/536c7/expandable-example-accordion.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "49.72972972972973%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABCUlEQVR42o2SCW6EMAxFuf9BEWTfEyDgsT0FTZlKBenLdohffpbBWA/WhUtn7Xy8RGN3Wefhr96BfjgfWJTHlKGUCiFGiKiUM/TeWdtPXLcN2rLw3Hv/F7DUCtZaGMeRNU0TrOsKG0JOUb0gMOPC/wJpEjmTUsI8zyzKCayUAiEE1wTlxZ8AU0oIEtgsQWvNAAIR/IyttefAWhuEEMB7z26dcww4joPV+45b78+3XEoBYww6UwwjVxkvhz7kwb4ffJYPgQUdVgR5dBgYTBfw+ZHLt8PyDaTEh8iiPOXCT8Vah7VnYMLncb/l1haee+8fhMJD1+aSUBQtKONYGh+rNJbHPyXP+KtXwwtn7P5SAHe1wAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A group of Expandable Containers in a accordion. The third one is expanded",
                "title": "A group of Expandable Containers in a accordion. The third one is expanded",
                "src": "/static/6dcafcf2db646cc128f8996563e9fa66/50383/expandable-example-accordion.png",
                "srcSet": ["/static/6dcafcf2db646cc128f8996563e9fa66/1efb2/expandable-example-accordion.png 370w", "/static/6dcafcf2db646cc128f8996563e9fa66/50383/expandable-example-accordion.png 740w", "/static/6dcafcf2db646cc128f8996563e9fa66/536c7/expandable-example-accordion.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun ExpandableContainerUiComponent(
    modifier: Modifier = Modifier,
    label: String,
    avatarConfig: ExpandableContainerAvatarConfig? = null,
    semanticState: SemanticState = SemanticState(),
    initiallyExpanded: Boolean = false,
    onExpandedStateChange: (expanded: Boolean) -> Unit = {},
    content: @Composable () -> Unit = {}
)
`}</code></pre>
        <h3 {...{
          "id": "props-1"
        }}>{`Props`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
              <th parentName="tr" {...{
                "align": "center"
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`label`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`String`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Text shown on the expandable container.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`avatarConfig`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ExpandableContainerAvatarConfig`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional avatar config for the container. Will only be used in the normal semantic state.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`semanticState`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`SemanticState`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`SemanticState()`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Adjusts the state of the Component. This allows for enabling, disabling, warning, error, and required states.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`initiallyExpanded`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Initial expanded state of the container. Defaults to collapsed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`onExpandedStateChange`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(expanded: Boolean) -> Unit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`{}`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Optional lambda to execute when we expand or collapse the container.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><strong parentName="td">{`content`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`@Composable () -> Unit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`{}`}</td>
              <td parentName="tr" {...{
                "align": "center"
              }}>{`Content slot for content that shows up below the title when expanded.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <p>{`Coming soon...`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      